.header {
    height: 40px
}

.button {
    border-radius: 15px !important;
    height: 50px;
    width: 174px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    color: black !important;
    font-size: 18px !important;
    font-weight: 600;
    background: white !important;
    border: 1px solid #000000 !important;
}

.button:hover {
    filter: brightness(80%) !important;
}

.crossed {
    text-decoration: line-through;
}

.green-gradient {
    background: linear-gradient(180deg, #82C795 0%, #538655 100%) !important;
}

.product-page-container {
    margin-top: 100px;
    display: flex;
    justify-content: center;
}

.product-info-container {
    display: flex;
    gap: 30px;
}

.main-product-image-container {
    width: 615px;
    height: 615px;
}

.product-image {
    width: 500px;
    height: 500px;
    border-radius: 15px !important;
}

.project-info-sidebar {
    width: 422px;
    height: 800px;
}

.product-title {
    width: 200px;
    font-weight: 700;
}

.product-bundle-image {
    margin-top: 10px;
    height: 75px;
    border-radius: 10px;
}

.bundle-container {
    margin-bottom: 10px;
}

.auth-bundle-container {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.checkbox-container {
    margin-top: 4px;
    margin-bottom: 4px;
    color: #9E9A9A;

}

.plus {
    font-size: 24px;
    color: #9D9B9B;
    margin-left: 12px;
    margin-right: 12px;
}

.green {
    color: #1C7A69 !important;
}

.product-description {
    width: 200px;
}

.discount-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1C7A69;
    color: white;
    font-size: 20px;
    line-height: 30px;
    height: 36px;
    width: 121px;

}

@media only screen and (max-width: 700px) {
    .main-product-image-container {
        width: 100%;
        height: 100%;
    }

    .product-image {
        width: 100%;
        height: auto;
    }

    .project-info-sidebar {
        width: 100%;
        height: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .product-info-container {
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
    }

    .product-description {
        width: unset;
        max-width: unset;
    }

    .discount-tag {
        display: none;
    }

    .product-title {
        width: unset;
    }

}