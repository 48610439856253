body {
  /* font-family: 'Poppins Medium', sans-serif !important; */
  background-color: white !important;
  font-family: poppins !important;
}

h1,
h2,
h3,
h4 {
  font-family: 'Poppins Medium', sans-serif !important;
}

span,
p {
  color: #2b2b2b;
}

div {
  font-size: 18px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.page-content {
  max-width: 1200px;
  width: 100%;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
}

.header-container {
  width: 100%;
  background-color: #fdf8f1;
  display: flex;
  justify-content: center;
}

.header-content {
  padding-top: 100px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-img {
  max-height: 390px;
}

.testimonial-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

@media only screen and (max-width: 700px) {
  .testimonial-container {
    flex-direction: column;
  }

  .testimonial-video-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.testimonial-video {
  border-radius: 25px !important;
}

.testimonial-description {
  font-size: 20px;
  text-align: center;
}

.testimonial-video-container {
  width: 534px;
  height: 366px;
}

.info-item {
  height: 500px;
  width: 100%;
}

.header-text-container {
  display: flex;
  align-items: center;
}

.benefits-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 20px;
  margin-right: 20px;
}

.benefit-item {
  display: flex;
}

.benefit-header-container {
  display: flex;
  align-items: center;
}

.benefit-description {
  margin-top: 10px;
  font-size: 18px;
}

.benefit-img {
  height: 80px;
  width: 80px;
  margin-top: 15px;
  border-radius: 15px;
}

.benefit-header {
  font-weight: 600;
  margin-left: 15px;
  line-height: 1.2;
  margin-top: 10px;
  font-size: 24px;
}

.card-image {
  height: 320px;
  width: 320px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.orange {
  color: #F69E88;
}

.product-info {
  padding: 10px;
  max-width: 300px;
}

.page-content-container {
  max-width: 1200px;
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

.news-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 2px solid orange;
  border-radius: 20px;
  padding: 20px;
  max-width: 320px;
}

.news-item {
  text-align: center;
}

.news-logo {
  height: 100px;
}

.news-headline {
  font-weight: 600;
  font-size: 18px;
}

.product-tag {
  bottom: 0px;
  right: 0;
  position: absolute;
  padding: 2px 30px;
  font-size: 16px;
  background-color: #ffcc00;
}

.product-grid {
  width: 100%;
  margin-bottom: 30px;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.graphic-info-container {
  display: inline-grid;
  grid-template-columns: 4fr 6fr;
  gap: 100px
}


.product-info-container {
  display: flex;
  justify-content: space-around;
  font-size: 18px;
}

.product-info {}

.product-info-list {
  margin-top: 0 !important;
}

.list-item {
  font-size: 18px !important;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 80px;
}

.stats-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.stat-number {
  font-size: 42px;
  color: orange;
  font-weight: 700;
}

.text-item {
  font-size: 18px;
}

.add-to-cart-btn {
  color: white !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  border-radius: 15px !important;
}

@media only screen and (max-width: 700px) {
  .benefits-container {
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    gap: 20px;
  }

  .research-card-container {
    flex-direction: column;
  }

  .product-info-container {
    flex-direction: column;
  }

  .testimonial-card-container {
    flex-direction: column;
    gap: 30px;
  }

  .header-content {
    flex-direction: column;
    padding-top: 0;
  }

  .header-img {
    display: none;
  }

  .page-content {
    margin: 0px;
  }

  .card-image {
    width: 300px;
    height: 300px;
  }

  .main-headline {
    margin-left: 20px;
  }

}

@media only screen and (max-width: 1100px) {
  #product-filler {
    display: none;
  }
}


@media only screen and (max-width: 800px) {
  .product-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
  }
}

.product-item-container {
  grid-column: span 1;
  grid-row: span 1;
}

.product-card {
  display: flex;
  width: fit-content !important;
  border-radius: 15px !important;
}

.product-image {
  position: relative;
  width: 320px;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.slashed-text {
  text-decoration: line-through;
}

.discount-price {
  color: green;
  margin-right: 10px;
}


.header #site_logo {
  height: 35px;
  width: auto !important;
}

.product-card .product-image {
  /* filter: brightness(0.7); */
  height: 240px;
  width: 330px;
}

.product-grid-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 240px;
  width: 100%;
  max-width: 1200px;
}

.grey-text {
  color: #646464;
}

.testimonial-card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.testimonial-card {
  position: relative;
  width: 374px;
  height: 610px;
  padding: 25px;
  font-size: 26px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.testimonial-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 520px;
  max-width: 330px;
}

.testimonial-text {
  position: relative;
  top: 30px;
}

.testimonial-img {
  width: 78px;
  height: 78px;
  border-radius: 50%;
}

.testimonial-name {
  font-size: 18px;
  text-align: right;
  position: relative;
  top: 20px;
}

.banner-img {
  background-image: url('/img/flower_banner.png');
  background-size: cover;
  background-repeat: repeat;
  height: inherit;
  width: 330px;
}

.product-name {
  font-size: 22px;
  font-weight: 600;
}

.product-description {
  max-width: 300px;
  font-size: 18px
}

.product-card .product-price {
  text-align: right;
}

.main-headline {
  font-size: 50px;
  font-weight: bold;
  color: #2b2b2b;
  line-height: 1.4;
}

.main-description {
  font-size: 28px;
  font-weight: bold;
  color: #2b2b2b;
  margin-top: 5px;
}

.research-highlight-details {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 30px;
}

.research-card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.research-card {
  height: 252px;
  width: 389px;
  border-radius: 26px;
  padding: 33px;
}

.research-card-text {
  font-size: 18px;
  margin-top: 22px;
}

.light-purple {
  background-color: #EDF2FC;
}

.light-orange {
  background-color: #FFF7EA;
}

.research-percent-circle {
  position: relative;
  width: 76px;
  height: 76px;
  background: #F69E87;
  border-radius: 50%;
  color: white;
  font-size: 26px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 180px;
  left: 220px;
}

.research-arrow-circle {
  position: relative;
  width: 46px;
  height: 46px;
  background: #F69E87;
  border-radius: 50%;
  color: white;
  font-size: 26px;
  margin: 0;
  bottom: 255px;
  left: 300px;
  display: flex;
  justify-content: center;
}

.research-small-circle {
  position: relative;
  width: 17px;
  height: 17px;
  background: #F69E87;
  border-radius: 50%;
  bottom: 250px;
  left: 300px;
}

.arrow-icon {
  margin-top: 4px !important;
  margin-right: 0 !important;
}

.purple {
  background-color: #7591F6;
}

.sri-sri-banner {
  width: 100%;
}

.banner-text-container {
  max-width: 1200px;
  width: 100%;
  position: relative;
  top: 350px;
  font-size: 16px;
}

.ui.green.ribbon.label {
  left: 103.5%;
  top: 40px;
  background-color: rgb(28, 122, 105) !important;
  border-color: rgb(20, 98, 84) !important;
}