html body {
  background-color: #f5f5f7;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;
  background-color: black;
  margin-bottom: 20px;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.current {
  border-bottom: 4px solid white;
}

h1 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}

.form p.description {
  color: #4c4c4c;
}

hr.small {
  margin: 0.5rem 0rem;
}


.full-width {
  width: 100%;
}

.page-header {
  border-bottom: 1px solid lightgray;
}

.page-header .logo {
  height: 20px;
}

.page-header, nav.navbar {
  background-color: white !important;
  width: 100%;
}

nav.navbar {
  padding: 0px 0px;
}

nav.navbar > * {
  height: 40px;
}

.page-header .navbar .ui.dropdown {
  background-color: white;
  font-size: 15px;
  color: #23527c;
}

.page-header .navbar .ui.dropdown.active {
  background-color: #ececec;
}


.navbar-nav {
  margin-top: 10px;
  margin: 0px auto;
}

.navbar a {
  font-weight: bold;
  font-size: 15px;
  padding-right: 1px;
}

.navbar a i {
  margin-right: 5px;
}


.page-header a {
  color: #23527c;
  margin: 0px 15px;
  padding-top: 8px;
}

.page-header a.active {
  color: #3E75BB;
  border-bottom: 3px solid #3E75BB;
}

body {
  background-color: #fbfbfd;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

/* semantic overrides */
.progress {
  height: auto !important;
}

.ui.list .header {
  font-size: 16px;
  font-weight: bold;
}

.ui.list .item {
  font-size: 15px;
  margin: 4px 0px;
}

.ui.progress {
  margin-bottom: 0px !important;
}

.ui.card .meta, .ui.cards>.card .meta {
  font-size: 0.8em;
}

.ui.card {
  box-shadow: none !important;
  border: 1px solid lightgray;
}

.ui.grid {
  margin:0px;
}
.ui.card.userInfoCard{
  width: 100%;
}

.ui.cards > .card, .ui.card, .ui.cards > .card > :first-child, .ui.card > :first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 6px !important;
}

/* converting material design to use same font as semantic */

.success {
  color: green;
}

.error {
  color: #9e2146;
}

.hidden {
  display: none;
}

.MuiInputBase-root, .MuiFormLabel-root {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

.ui.vertical.tabular.menu .item {
  padding: 20px;
  font-size: 1.1em;
}


.ui.vertical.menu>.active.item:first-child {
  font-weight: bold;
  color: #4D79A2;
}

.ui.vertical.text.menu .item {
  padding: 10px 0px;
  font-size: 16px;
}
.account-details .input:first-child {
  margin-top: 0px;
}

.ui.orange.button, .ui.orange.buttons .button {
  background-color: #0D3A5D;
}

.ui.orange.button:hover, .ui.orange.button:active, .ui.orange.button:focus {
  background-color: #09253c;
}

.ui.button.primary, .ui.buttons.primary .button {
  background-color: #0D3A5D;
}

.ui.button.primary:hover, .ui.button.primary:active, .ui.button.primary:focus {
  background-color: #09253c;
}

.create-campaign .input, .create-campaign .button {
  width: 100%;
}

.ui.progress {
  margin: 0px;
  margin-bottom: 10px;
}

.ui.form .input-box {
  margin: 15px 0px;
}

.ui.form .form-label {
  font-weight: bold;
}

.ui.form .field>label {
  margin-bottom: 7px !important;
}

.create-campaign .input:first-child {
  margin: 0px 0px !important;
}

.create-campaign-form {
  width: 100%;
}

.ui.bottom.attached.segment.active.tab {
  padding: 20px 30px;
}

.ui.form .campaign-dates input[type=text] {
  border: 0px;
}

.responsive-group > div {
  padding: 0px 2px;
}

.form .social-media.input .icon {
  position: relative;
  top: 15px;
  /* font-size: 21px; */
  top: 25px;
  text-align: center;
}

.create-campaign .input.datepicker {
  width: 95%;
}

.create-campaign .input.datepicker.end {
  padding-left: 8px;
}

.billing-breakdown {
  list-style-type:none;
}

.form-header {
  font-size: 16px;
  font-weight: bold;
}

.form-header i {
  font-size: 14px;
  position: relative;
  top: -1px;
}

.billing-breakdown .billing-amount{
  float: right;
}

.billing-breakdown .total .billing-name {
  font-weight:bold;
}

.billing-details .total {
  margin-top: 7px;
}

/* login page */ 

.page.login .login-card{
  font-size: 16px;
}

.page.login .login-header {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.page.login a {
  font-size: 0.8em;
}


.page.login .input, .page-login .login{
  width: 100%;
  margin-top: 5px;
}

.page.login .ui.card {
  width: 100%;
}

.page.login .login-more {
  margin-top: 5px;
  text-align: center;
}

.page .create-campaign-form a {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}

.page .create-campaign-form a:hover {
  color: #0056b3  !important;
  text-decoration: underline;
}

.table-link {
  width: 100%;
}
.ui.modal{
  background: #fff0;
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}


#sidebar {
  position: fixed;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 68px;
  padding-left: 0;
  padding-right: 0;
}

#sidebar .ui.menu > a.item {
  padding: 10px 20px;
  line-height: 20px;
  color: #337ab7;
  border-radius: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
}

#sidebar .ui.menu > a.item.active {
  background-color: #337ab7;
  color: white;
  border: none !important;
}

#sidebar .ui.menu > a.item:hover {
  background-color: #eee;
  color: #23527c;
}

#content {
  padding-top: 56px;
  padding-left: 20px;
  padding-right: 20px;
}

#content h1 {
  font-size: 36px;
}

#content .ui.dividing.header {
  width: 100%;
}

.ui.centered.small.circular.image {
  margin-top: 14px;
  margin-bottom: 14px;
}

.ui.borderless.menu {
  box-shadow: none;
  flex-wrap: wrap;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.ui.mobile.only.grid .ui.menu .ui.vertical.menu {
  display: none;
}

/* admin pages */

.ui.vertical.text.menu.admin-menu:first-child {
  margin-top: 15px;
  text-align: left;
}

.admin-menu i {
  margin-right: 12px;
}

.page .ui.breadcrumb {
  float: left;
}

.page.admin.campaign .create-campaign-form {
  margin-top: 10px;
}

/* .page .ui.card>.content, .ui.cards>.card>.content {
  background: #f9fafb;
} */

.page .ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
  font-size: 1.1em;
}

.ui.card .content.edit-campaign {
  padding: 20px 100px;
}

.content.edit-campaign .campaign-details {
  max-width: 500px;
}

.edit-campaign .input {
  width: 100%;
  margin: 10px 0px;
}

.ui.card .content-body {
  background-color: #f7fafc;
}

.input label {
  font-weight: bold; 
  margin-bottom: 2px;
}


